/**
 * @fileoverview Fleet Editor outer level component
 */

import React, { useState } from "react";
import ResetButton from "components/ResetButton";
import FleetGrid from "components/fleet-editor/FleetGrid";
import { jsonToCsv } from "utils/jsonToCsv";
import { Button } from "components/ui/button";
import useProForma from "store/useProForma";
import useAuthStore from "store/useAuthStore";
import ToggleNav from "components/ToggleNav";
import { getIndivCharger } from "components/fleet-editor/getCharger";
import { usePostHog } from "posthog-js/react";
const filterOut = (obj) => {
  obj.forEach((item) => {
    delete item.equipment_id;
    delete item.user_id;
    delete item.id;
  });
  return obj;
};

function FleetEditor() {
  const { user } = useAuthStore();
  const posthog = usePostHog();
  const { fleetData, cityInfo } = useProForma();

  const getFormattedDate = () => {
    const date = new Date();
    const yy = date.getFullYear().toString().slice(-2);
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yy}_${mm}_${dd}`;
  };
  const filename = `${
    user?.email.split("@")[0]
  }_Fleet_${getFormattedDate()}.csv`;
  //

  /**
   * @function downloadCsv - Downloads the fleet data as a CSV file
   */
  const downloadCsv = () => {
    const data = [...fleetData];
    data.map((item) => {
      delete item["electrification_scenario"];
      return item;
    });
    const customColumnMappings = {
      description: "Description",
      state_incentives: "State or Local Incentives Rebates or Credits",
      "Replacement Year": "Electrification Year",
      "Simplified Domicile": "Domicile",
      "EV Purchase Cost pre-incentive":
        "EV Purchase Cost (Not Including Incentives)",
      "Existing Vehicle Annual Maintenance":
        "Existing Vehicle Annual Maintenance Costs",
      "IRA Incentives": "Federal Incentives Rebates or Credits",
      ghg: "Existing Vehicle GHG Emissions",
      vehicle_type: "Vehicle Type",
      charger_assignment: "Charger Assignment",
    };

    data.forEach((item) => {
      if (item.charger_assignment) {
        item.charger_assignment = getIndivCharger(
          cityInfo,
          item.charger_assignment
        );
      }
    });
    const csv = jsonToCsv(filterOut(data), customColumnMappings);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  // Row Data: The data to be displayed.
  const [rowData, setRowData] = useState(null);

  // Store gridApi to access selected rows
  const [gridApi, setGridApi] = useState(null);

  return (
    <div className="h-screen">
      <div className="flex lg:flex-col lg:mb-4 justify-between mb-4">
        <div className="flex items-center w-full justify-between p-5">
          <div>
            <div className="flex justify-between gap-2">
              <ToggleNav />
            </div>
            <div className="text-[#40423f] text-[23px] font-medium font-['Roboto'] leading-7">
              Fleet Editor
            </div>
            <div className="text-[#656864] text-[17px] font-medium font-['Inter'] leading-tight">
              Fleet inventory and analysis
            </div>
          </div>
          <div className="bg-white p-4 rounded-full flex gap-2">
            <ResetButton tableName="fleet data" />
            <Button
              variant="outline"
              onClick={() => {
                downloadCsv();
                posthog.capture("export fleet data");
              }}
              className="rounded-full flex gap-2 bg-white"
            >
              <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none flex gap-2 items-center">
                <img src="icons/exportIcon.svg"></img>Export to .csv
              </div>
            </Button>
          </div>
        </div>

        <div>
          {/* <AddDeleteFleet  rowData={rowData} setRowData={setRowData} gridApi={gridApi}/> */}
        </div>
      </div>
      <div className="flex rounded-3xl gap-6 bg-white p-6 h-[calc(100vh-130px)] w-full">
        <FleetGrid
          rowData={rowData}
          setRowData={setRowData}
          gridApi={gridApi}
          setGridApi={setGridApi}
        />
      </div>
    </div>
  );
}

export default FleetEditor;
