/**
 * @fileoverview used to create a supabase client, should not be modified
 */
import { createClient } from "@supabase/supabase-js";
import useAuthStore from "./store/useAuthStore";

const supabaseUrl = `${process.env.REACT_APP_SUPABASE_URL}`;
const supabaseAnonKey = `${process.env.REACT_APP_SUPABASE_ANON}`;

// Default client for unauthenticated access
const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const getAuthenticatedClient = () => {
  const token = useAuthStore.getState().getAccessToken();
  if (!token) return null;
  return createClient(supabaseUrl, supabaseAnonKey, {
    global: {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  });
};

export default supabase;
