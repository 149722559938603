/**
 * @fileoverview cityInfo.js contains functions to fetch and patch city info from the database
 *
 */
import useAuthStore from "../store/useAuthStore";
import { getAuthenticatedClient } from "../supabaseClient";

// import supabase from "../supabaseClient";

export const patchCityInfo = async (data) => {
  const { user } = useAuthStore.getState();
  if (!user) {
    return;
  }
  const supabase = getAuthenticatedClient();
  try {
    const { data: responseData, error } = await supabase
      .from("city info")
      .upsert([{ id: user.id, ...data }])
      .select("*");

    if (error) {
      throw error;
    }
    return responseData;
  } catch (err) {
    console.error("Error updating city info:", err);
    throw err;
  }
};

export const fetchCityInfo = async () => {
  try {
    const { user } = useAuthStore.getState();
    if (!user) return;
    const supabase = getAuthenticatedClient();
    const { data, error } = await supabase
      .from("city info")
      .select("*")
      .eq("id", user.id);

    if (error) {
      throw error;
    }

    if (data.length === 0) {
      // If it doesn't exist, create a new entry
      const { data: newData, error: newError } = await supabase
        .from("city info")
        .insert([{ id: user.id, city_name: "" }])
        .select("*");

      if (newError) {
        throw newError;
      }
      return { data: newData };
    }

    return { data };
  } catch (error) {
    console.error("Error fetching city info:", error);
  }
};

export const updateCityInfo = async (userId, updates) => {
  const supabase = getAuthenticatedClient();

  try {
    const { data, error } = await supabase
      .from("city info")
      .update(updates)
      .eq("id", userId)
      .select("*");

    if (error) {
      return { error: error.message };
    }

    if (data.length === 0) {
      return { error: "City info not found" };
    }

    return { data };
  } catch (error) {
    console.error("Error updating city info:", error);
    throw error;
  }
};
