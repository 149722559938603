/**
 * @fileoverview This store manages a Cache of the user's data, so that data does not always have to be fetched upon updates
 */

import { create } from "zustand";
import useProForma from "./useProForma";
import { patchCityInfo } from "../api/cityInfo";

const useCache = create((set) => ({
  fleetData: [],
  advancedControls: null,
  controls: { data: null },
  phases: [],
  chargerCost: null,
  cityInfo: null,

  /**
   * These functions are all called when the data is fetched and the Cache is set for the first time
   */
  setFleetData: (data) => set({ fleetData: data }),
  setAdvancedControls: (data) => set({ advancedControls: data }),
  setControls: (data) => set({ controls: { data } }),
  setPhases: (data) => set({ phases: data }),
  setChargerCost: (data) => set({ chargerCost: data }),
  setCityInfo: (data) => set({ cityInfo: data }),

  /**
   * @function updateControls - Updates the controls in the cache, taking in key and value pair
   */
  updateControls: (attribute, value) => {
    const { fetchAndUpdateFleet } = useProForma.getState();
    set((state) => {
      const updatedControls = [...state.controls.data];
      if (updatedControls.length > 0) {
        updatedControls[0] = {
          ...updatedControls[0],
          [attribute]: value,
        };
      }
      return { controls: { data: updatedControls } };
    });

    // fetch and update data, passing in none because no new data needs to be fetched, since cache is updated
    fetchAndUpdateFleet("none");
  },
  /**
   * @function updateAdvancedControls - Updates the advanced controls in the cache, taking in key and value pair as a single object
   * (should change this for consistency with other fucntions)
   */
  updateAdvancedControls: (updates) => {
    const { fetchAndUpdateFleet } = useProForma.getState();
    set((state) => {
      const updatedControls = [...state.advancedControls.data];
      if (updatedControls.length > 0) {
        updatedControls[0] = {
          ...updatedControls[0],
          ...updates,
        };
      }
      return { advancedControls: { data: updatedControls } };
    });

    // fetch and update data, passing in none because no new data needs to be fetched, since cache is updated
    fetchAndUpdateFleet("none");
  },

  /**
   * @function updateCityInfo - Updates the city info in the cache, taking in key and value pair
   */
  updateCityInfo: (attribute, value) => {
    const { fetchAndUpdateFleet } = useProForma.getState();
    patchCityInfo({ [attribute]: value });
    set((state) => {
      const updatedCityInfo = [...state.cityInfo.data];
      if (updatedCityInfo.length > 0) {
        updatedCityInfo[0] = {
          ...updatedCityInfo[0],
          [attribute]: value,
        };
      }
      return { cityInfo: { data: updatedCityInfo } };
    });

    // fetch and update data, passing in none because no new data needs to be fetched, since cache is updated
    fetchAndUpdateFleet("none");
  },
}));

export default useCache;
