import { getAuthenticatedClient } from "../supabaseClient";

/**
 * @function createControls - function to add control to database
 *
 * @param {*} defaultControlsData object containing control data to update
 * @returns api response
 */
export const createControls = async (defaultControlsData) => {
  const supabase = getAuthenticatedClient();

  const {
    id,
    electrification_scenario,
    site,
    incentives,
    ira_incentives,
    evse_incentives,
  } = defaultControlsData;

  if (
    !id ||
    typeof incentives === "undefined" ||
    typeof ira_incentives === "undefined" ||
    typeof evse_incentives === "undefined"
  ) {
    return { error: "All fields are required" };
  }

  try {
    const { data, error } = await supabase.from("controls").insert([
      {
        id,
        electrification_scenario,
        site,
        incentives,
        ira_incentives,
        evse_incentives,
      },
    ]);

    if (error) {
      throw error;
    }

    return { data };
  } catch (error) {
    return { error: error.message };
  }
};

export const updateControlsNew = async (userId, attribute, value) => {
  const supabase = getAuthenticatedClient();

  if (!attribute || typeof value === "undefined") {
    return { error: "Attribute and value are required" };
  }

  try {
    const updateData = { [attribute]: value };

    const { data, error } = await supabase
      .from("controls")
      .update(updateData)
      .eq("id", userId)
      .select();

    if (error) {
      throw error;
    }

    if (data.length === 0) {
      return { error: "Controls not found for this user" };
    }

    return { data: data[0] };
  } catch (error) {
    return { error: error.message };
  }
};
