/**
 * @fileoverview Pro Forma account addition page
 */

import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { useNavigate } from "react-router-dom";
import { addChargerCost } from "../api/chargercostapi";
import { addAdvancedControl } from "../api/advancedcontrolsapi";
import { createControls } from "../api/controlsapi";
import { createUserMetadata } from "../api/usermetadataapi";
import useAuthStore from "../store/useAuthStore";
import { uploadWithOutDelete } from "../api/uploadapi";

const Admin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [csvFile, setCsvFile] = useState(null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const { signUp, fetchSignupData } = useAuthStore();

  const onDrop = (acceptedFiles) => {
    setCsvFile(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  /**
   * @function handleSignup - Handles the form submission, creates a new user with the given email and password
   * adds optonyusa.com to the email and uploads the CSV file if it exists
   */
  const handleSignup = async (e) => {
    e.preventDefault();
    const newEmail = email + "@optonyusa.com";
    const { data, error } = await signUp(newEmail, password);

    if (error) {
      setMessage(`${error.message}`);
    } else {
      await uploadCSVFile(data.user.id);
      fetchSignupData(data.user.id);
    }
  };

  /**
   * @function uploadCSVFile - Uploads the CSV file to the server and creates the default controls data
   * Note***** If the controls data fails to upload, the user is deleted
   */
  const uploadCSVFile = async (userId) => {
    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("userId", userId);

    try {
      const defaultControlsData = {
        id: userId,
        electrification_scenario: "",
        site: "",
        incentives: false,
        ira_incentives: false,
        evse_incentives: true,
      };
      const defaultAdvancedControls = {
        id: userId,
        inflation: false,
        inflation_escalation_rate: 3,
        electricity_escalation_rate: 3,
        gasoline_escalation_rate: 3,
        infrastructure_loan_term: 10,
        infrastructure_loan_interest_rate: 3,
        discount_rate_npv: 3,
        maintenance_costs_annual_per_station: 0,
        charging_optimization: false,
        charging_optimization_savings: 0,
        charge_management_subscription_costs: 0,
        charger_network_costs: 0,
      };

      // Call the /api/controls endpoint with the default data
      const controlsResponse = await createControls(defaultControlsData);
      const advancedControlRes = await addAdvancedControl(
        defaultAdvancedControls
      );

      const chargerCostAdd = await addChargerCost(userId);

      const userMetadata = await createUserMetadata(userId);

      if (
        controlsResponse.error ||
        advancedControlRes.error ||
        chargerCostAdd.error ||
        userMetadata.error
      ) {
        throw new Error("Failed to set controls data");
      }

      const response = await uploadWithOutDelete(formData);
      if (response.error) {
        const errorMessage = `CSV format is incorrect.\nServer message: ${response.error}`;
        throw new Error(errorMessage);
      }
      setMessage(`${response.message}`);

      navigate("/");
    } catch (error) {
      setMessage(`Error parsing CSV: ${error.message}`);
    }
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <Card className="h-[500px] w-[500px] p-4">
        <CardHeader className="pb-5">
          <CardTitle>Pro Forma Account Addition</CardTitle>
          <CardDescription>Add Pro Forma Users Here</CardDescription>
          <p className="text-sm text-red-400">{message}</p>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSignup}>
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-1.5">
                <Label>New Username</Label>
                <Input
                  type="text"
                  placeholder="username"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <Label>New Password</Label>
                <Input
                  type="text"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div
                className="border border-4 rounded-md border-dashed p-5 hover:border-gray-400 text-center"
                {...getRootProps()}
              >
                <Input {...getInputProps()} />
                {csvFile ? (
                  <p>{csvFile.name}</p>
                ) : (
                  <p>Drag & Drop Vehicle CSV file here</p>
                )}
              </div>

              <div className="flex flex-col space-y-1.5">
                <Button type="submit">Create Account</Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Admin;
