/**
 * @fileoverview Admin Login Page, contains hashed password for admin login
 */

import React, { useState } from "react";
import bcrypt from "bcryptjs";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Button } from "../components/ui/button";
import useAdminAuth from "../store/useAdminAuth";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../components/ui/card";
import { useNavigate, useLocation } from "react-router-dom";

//hashed optony passcode
const hashedPassword =
  "$2a$10$KMT5ouKxlgii5OtxIXlaZOQKzGqGriwF6HxAhOKQr1f7KQ/ztu3Y6";

const Login = () => {
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const { login } = useAdminAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || "/admin";

  /**
   * @function handleSubmit - Handles the form submission, compares password given to the hashed password
   * routes to the admin page if correct, displays error message if incorrect
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if (bcrypt.compareSync(password, hashedPassword)) {
      login();
      navigate(from, { replace: true });
    } else {
      setMessage("Incorrect password");
    }
  };

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Card className="h-[300px] w-[400px] p-4">
        <CardHeader className="pb-5">
          <CardTitle>Admin Login</CardTitle>
          <CardDescription>Enter the admin password to access</CardDescription>
          <p className="text-sm text-red-400">{message}</p>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit}>
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-1.5">
                <Label>Password</Label>
                <Input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <Button type="submit">Login</Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;
