/**
 * @fileoverview
 * This component contains the form for updating the charger information in the SetInfo page of the admin page.
 *
 *
 * Parent element: AdminSetInfo.js.js
 */

import React, { useState, useEffect } from "react";
import useProForma from "store/useProForma";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components//ui/input";
import useAuthStore from "store/useAuthStore";
import { useToast } from "components/hooks/use-toast";
import { Separator } from "components/ui/separator";
import { updateChargerData } from "api/chargercostapi";
const ChargerInfoForm = () => {
  const {
    chargerCost: chargerCosts,
    cityInfo,
    fetchAndUpdateFleet,
  } = useProForma(); // Assuming this fetches the charger data for the current user
  const [formData, setFormData] = useState({
    cost_less_than_10_kw: null,
    cost_10_20_kw: null,
    cost_25_kw: null,
    cost_180_200_kw: null,
    install_less_than_10_kw: null,
    install_10_20_kw: null,
    install_25_kw: null,
    install_180_200_kw: null,
  });

  const { toast } = useToast();
  // Updates the form data when the chargerCosts loads
  useEffect(() => {
    if (chargerCosts) {
      setFormData({
        cost_less_than_10_kw: chargerCosts.cost_less_than_10_kw ?? null,
        cost_10_20_kw: chargerCosts.cost_10_20_kw ?? null,
        cost_25_kw: chargerCosts.cost_25_kw ?? null,
        cost_180_200_kw: chargerCosts.cost_180_200_kw ?? null,
        install_less_than_10_kw: chargerCosts.install_less_than_10_kw ?? null,
        install_10_20_kw: chargerCosts.install_10_20_kw ?? null,
        install_25_kw: chargerCosts.install_25_kw ?? null,
        install_180_200_kw: chargerCosts.install_180_200_kw ?? null,
      });
    }
  }, [chargerCosts]);

  // Updates the form data when the input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value === "" ? null : value,
    }));
  };

  // Submits the form data to the backend, calls fetchAndUpdateFleet to update data
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await updateChargerData(chargerCosts.id, formData);

      if (response.error) {
        throw new Error("Failed to update data");
      }

      toast({
        title: "Success",
        description: `Charger Data has been saved`,
      });
      fetchAndUpdateFleet();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="grid gap-6">
        {/* Cost Settings */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Charger Costs</h3>
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <Label>{`Cost ${cityInfo?.range_1 || "< 10 kW"}`}</Label>
              <Input
                type="number"
                name="cost_less_than_10_kw"
                value={formData.cost_less_than_10_kw}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>{`Cost ${cityInfo?.range_2 || "10-20 kW"}`}</Label>
              <Input
                type="number"
                name="cost_10_20_kw"
                value={formData.cost_10_20_kw}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>{`Cost ${cityInfo?.range_3 || "40 kW"}`}</Label>
              <Input
                type="number"
                name="cost_25_kw"
                value={formData.cost_25_kw}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>{`Cost ${cityInfo?.range_4 || "180-200 kW"}`}</Label>
              <Input
                type="number"
                name="cost_180_200_kw"
                value={formData.cost_180_200_kw}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <Separator />

        {/* Installation Costs */}
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Installation Costs</h3>
          <div className="grid gap-4 sm:grid-cols-2">
            <div className="space-y-2">
              <Label>{`Install Cost ${cityInfo?.range_1 || "< 10 kW"}`}</Label>
              <Input
                type="number"
                name="install_less_than_10_kw"
                value={formData.install_less_than_10_kw}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>{`Install Cost ${cityInfo?.range_2 || "10-20 kW"}`}</Label>
              <Input
                type="number"
                name="install_10_20_kw"
                value={formData.install_10_20_kw}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>{`Install Cost ${cityInfo?.range_3 || "40 kW"}`}</Label>
              <Input
                type="number"
                name="install_25_kw"
                value={formData.install_25_kw}
                onChange={handleInputChange}
              />
            </div>
            <div className="space-y-2">
              <Label>{`Install Cost ${
                cityInfo?.range_4 || "180-200 kW"
              }`}</Label>
              <Input
                type="number"
                name="install_180_200_kw"
                value={formData.install_180_200_kw}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <Button type="submit">Update</Button>
        </div>
      </div>
    </form>
  );
};

export default ChargerInfoForm;
