import React from "react";
import { Button } from "../ui/button";
import PropTypes from "prop-types";
import { usePostHog } from "posthog-js/react";

const ContactPromoCard = ({ isCollapsed = false }) => {
  const posthog = usePostHog();

  const handleContactClick = () => {
    posthog?.capture("Contact Us clicked");
  };

  if (isCollapsed) {
    return (
      <div className="w-[200px]">
        <a
          href="mailto:Energy@OptonyUSA.com"
          className="w-full"
          onClick={handleContactClick}
        >
          <Button className="w-full h-10 px-4 flex gap-2" variant="outline">
            <div className="text-[#1b1c1b] text-[14.95px] font-medium font-['Roboto'] leading-[17.94px]">
              Contact us
            </div>
            <img src="icons/sideArrow.svg" alt="arrow" />
          </Button>
        </a>
      </div>
    );
  }

  return (
    <div
      style={{ backgroundImage: "url(/treeimg.png)" }}
      className="h-[200px] w-[200px] rounded-2xl gap-4 flex flex-col items-start justify-center px-5"
    >
      <div className="text-white text-[17.35px] font-sm font-['Inter']">
        Optony is working for a greener tomorrow
      </div>
      <a href="mailto:Energy@OptonyUSA.com" onClick={handleContactClick}>
        <Button className="h-10 px-4 flex gap-2" variant="outline">
          <div className="text-[#1b1c1b] text-[14.95px] font-medium font-['Roboto'] leading-[17.94px]">
            Contact us
          </div>
          <img src="icons/sideArrow.svg" alt="arrow" />
        </Button>
      </a>
    </div>
  );
};

ContactPromoCard.propTypes = {
  isCollapsed: PropTypes.bool,
};

export default ContactPromoCard;
