// todo: create supabase edge function for this
export const recoverUser = async (username) => {
  const response = await fetch(
    `https://jcvmwzualzmymhcghnro.supabase.co/functions/v1/forgot-password`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_ANON}`,
      },
      body: JSON.stringify({ username }),
    }
  );
  const responseData = await response.json();
  if (responseData && responseData.success) {
    return true;
  }
  return false;
};

// todo: create supabase edge function for this
export const adminFetchUser = async (username) => {
  console.log(process.env.REACT_APP_SUPABASE_ANON);
  console.log(username);
  const response = await fetch(
    `https://jcvmwzualzmymhcghnro.supabase.co/functions/v1/admin-recover-user`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_ANON}`,
      },
      body: JSON.stringify({ username }),
    }
  );
  const responseData = await response.json();
  if (responseData.link.properties) {
    return responseData.link.properties.action_link;
  }
  return null;
};
