/**
 * @fileoverview
 * This is the advanced controls component that appears when the user clicks on the advanced controls button in the dashboard.
 *
 * The logic for the updating of the advanced controls is handled in the Economics.js and SoftwareCosts.js components.
 *
 *
 * Child elements: Economics.js, SoftwareCosts.js, ResetButton.js
 *
 */

import React from "react";
import Economics from "./Economics";
import SoftwareCosts from "./SoftwareCosts";
import { usePostHog } from "posthog-js/react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverAnchor,
  PopoverClose,
} from "../ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import ResetButton from "../ResetButton";
import { PRIMARY_COLOR } from "colors";

const AdvControlsPopup = () => {
  const posthog = usePostHog();

  const handlePopoverOpen = () => {
    posthog?.capture("Advanced Controls Opened");
  };

  return (
    <Popover onOpenChange={(open) => open && handlePopoverOpen()}>
      <PopoverAnchor className="absolute right-[250px] top-0 z-50"></PopoverAnchor>
      <PopoverTrigger className="absolute right-16 top-4 z-50">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <button className="bg-white rounded-full hover:bg-[#e4e6e4]">
                <img className="" src="Icon button.svg"></img>
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Advanced controls</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </PopoverTrigger>
      <PopoverContent className="bg-[#FFFFFF] h-[95vh] flex flex-col gap-2 overflow-visible rounded-3xl py-4 px-0 w-[400px]">
        <div className="px-4 py-2 flex justify-between items-start">
          <ResetButton buttonType="light" tableName="advanced controls" />
          <PopoverClose className="relative ">
            <img src="XIcon.svg"></img>
          </PopoverClose>
        </div>
        <div className="h-[0px] border-[0.5px] border-[#cacdc9]"></div>
        <div className="px-4">
          <div className="text-[#7e817d] text-[11px] font-medium font-['Roboto'] leading-[13.20px] mb-4">
            Economic Controls
          </div>

          <Economics />
        </div>

        <div className="h-[0px] border-[0.5px] border-[#cacdc9]"></div>
        <div className="px-4">
          <div className="text-[#7e817d] text-[11px] font-medium font-['Roboto'] leading-[13.20px] mb-4">
            Software Controls
          </div>
          <SoftwareCosts />
        </div>
        <div className="px-4 w-full absolute bottom-4">
          <a href="mailto:Energy@OptonyUSA.com">
            <div
              style={{ color: PRIMARY_COLOR }}
              className={` text-sm font-medium font-['Roboto'] leading-[16.80px] hover:underline`}
            >
              Contact us to learn more about our default values
            </div>
          </a>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default AdvControlsPopup;
