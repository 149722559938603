/**
 * @fileoverview This file contains utility functions for updating advanced controls.
 * It does two things: update the database with an API request, and update the cache (updateAdvancedControls)
 */

import useCache from "store/useCache";
import { patchAdvancedControl } from "../../api/advancedcontrolsapi";

export const useUpdateAdvancedControl = () => {
  const { updateAdvancedControls } = useCache();

  const updateAdvancedControl = async (id, updatePayload) => {
    try {
      // Update the cache first
      updateAdvancedControls(updatePayload);

      // Use the API function instead of direct fetch
      const { data, error } = await patchAdvancedControl(id, updatePayload);

      if (error) {
        throw new Error(`Failed to update advanced control: ${error}`);
      }

      return data;
    } catch (error) {
      console.error("Error updating advanced control:", error);
      throw error;
    }
  };

  return updateAdvancedControl;
};
