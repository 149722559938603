import { getAuthenticatedClient } from "../supabaseClient";

/**
 * @function addChargerCost - Charger Cost added, called when initializing new user
 * @param {*} userId - userId to be updated
 * @returns api response
 */
export const addChargerCost = async (userId) => {
  if (!userId) {
    throw new Error("User ID required");
  }

  const supabase = getAuthenticatedClient();

  const { data, error } = await supabase.from("charger costs").insert({
    id: userId,
  });

  if (error) {
    throw error;
  }

  return { message: "Charger cost added successfully", data };
};

export const updateChargerData = async (userId, updates) => {
  const supabase = getAuthenticatedClient();

  try {
    const { data, error } = await supabase
      .from("charger costs")
      .update(updates)
      .eq("id", userId)
      .select("*");

    if (error) {
      return { error: error.message };
    }

    if (data.length === 0) {
      return { error: "Charger data not found" };
    }

    return { data };
  } catch (error) {
    console.error("Error updating charger data:", error);
    throw error;
  }
};
