import React, { useState, useEffect } from "react";
import { Button } from "components/ui/button";
import {
  fetchMasterAccess,
  fetchMasterAccessByCode,
  updateFirstLogin,
} from "api/masteraccessapi";
import { AspectRatio } from "components/ui/aspect-ratio";
import ToggleNav from "components/ToggleNav";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from "../components/ui/dialog";

const MasterAccessDashboard = () => {
  const [cityAccess, setCityAccess] = useState(null);
  const [clickedLinks, setClickedLinks] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const navigate = useNavigate();

  const location = useLocation();
  const { code } = location.state || {};

  useEffect(() => {
    if (!code) {
      navigate("/login"); // Redirect to /login if code is missing
      return;
    }

    const fetchData = async () => {
      const data = await fetchMasterAccess(code);
      const data2 = await fetchMasterAccessByCode(code);
      setCityAccess(data);
      if (data2.first_login) {
        setShowDialog(true); // Show terms and conditions dialog
      }
    };

    fetchData(); // Call the async function
  }, [code, navigate]);

  const handleFinalSubmit = async () => {
    try {
      await updateFirstLogin(code);
      setShowDialog(false);
    } catch (error) {
      console.error("Error handling terms agreement:", error);
    }
  };

  const handleLinkClick = async (index) => {
    setLoadingStates((prev) => ({ ...prev, [index]: true }));
    try {
      const data = await fetchMasterAccess(code);
      const newLink = data[index].link;
      window.open(newLink, "_blank");
      setClickedLinks((prev) => ({
        ...prev,
        [index]: true,
      }));
      setCityAccess(data);
      setClickedLinks({});
    } finally {
      setLoadingStates((prev) => ({ ...prev, [index]: false }));
    }
  };

  return (
    <div className="w-full h-screen p-4">
      <div className="flex justify-between gap-2 mb-1">
        <ToggleNav />
      </div>
      <div className="w-full relative">
        <AspectRatio className="" ratio={10 / 3}>
          <img
            src="/ProFormaHeaderCompressed.png"
            alt="Image"
            className="h-full w-full rounded-t-3xl object-cover"
          />
        </AspectRatio>
        <div className="w-full h-16 rounded-b-3xl bg-optonyprimary flex items-center px-2">
          <p className="text-white text-lg ml-4">
            OPTONY FLEET ELECTRIFICATION PRO FORMA TOOL
          </p>
        </div>
      </div>
      <div className="bg-gray2 w-full rounded-3xl p-6 mt-4">
        <div className="border bg-gray-50 w-full font-['Inter'] rounded-2xl flex flex-col gap-2 p-6 shadow-md">
          <h2 className="text-2xl text-gray-800">
            Welcome to the Fleet Electrification Pro Forma!
          </h2>
          <p className="text-gray-700 text-sm leading-relaxed">
            You may access the Fleet Electrification Pro Forma tools assigned to
            your credentials. Select any Fleet Electrification Pro Forma below
            to view or edit the account.
          </p>

          <h3 className="text-md text-gray-800">Overview</h3>
          <p className="text-gray-700 text-sm leading-relaxed">
            The Fleet Electrification Pro Forma consists of a summary of the
            costs and benefits associated with fleet electrification and
            charging infrastructure.
          </p>

          <h3 className="text-md text-gray-800">Purpose</h3>
          <p className="text-gray-700 text-sm leading-relaxed">
            This tool may be used for years to come when making decisions
            related to fleet electrification, including evaluating funding
            opportunities, vehicle replacement options, and EV charging options.
            All values provided are estimates and subject to change.
          </p>
        </div>
        <div className="w-full bg-gray-50 shadow-md rounded-2xl border mt-4 p-6">
          <p className="text-2xl font-['Inter']">Your Pro Forma Access</p>
          {cityAccess && (
            <div className="mt-2 w-[350px] border bg-white rounded-lg flex flex-col p-2 items-center">
              {cityAccess.map((city, index) => (
                <div
                  key={index}
                  className={`flex justify-between p-2 items-center w-full ${
                    index !== 0 ? "border-t border-gray-200" : ""
                  }`}
                >
                  <span className="font-medium text-gray-800">
                    {city.username}
                  </span>
                  <Button
                    onClick={() => handleLinkClick(index)}
                    className="ml-2 px-4 py-2 text-white font-medium rounded-md transition-colors"
                    disabled={clickedLinks[index] || loadingStates[index]}
                  >
                    {loadingStates[index] ? (
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                        ></path>
                      </svg>
                    ) : (
                      "Access"
                    )}
                  </Button>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {showDialog && (
        <Dialog open={showDialog} onOpenChange={(open) => setShowDialog(open)}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Terms and Conditions</DialogTitle>
            </DialogHeader>
            <div
              className="max-h-60 overflow-y-auto mb-4 p-4 border border-gray-200"
              style={{ fontFamily: "Times New Roman, Times, serif" }}
            >
              <p>
                By accessing and using this digital tool, you acknowledge and
                agree to the following Terms and Conditions:
              </p>
              <p>
                Upon first signing into your account, you may need to create a
                new account password to use all or part of the Pro Forma tool.
                Your username and password are for Optony client use only and
                should be kept confidential. You understand that you are
                responsible for all authorized or unauthorized use of your
                username and password. Notify the Optony team immediately if
                your account access information is lost or stolen, or if you
                believe there has been unauthorized access to your account.
              </p>
              <p>
                By creating and using an account on this platform, you consent
                to host your data on our servers, and you are solely responsible
                for the content and accuracy of the data you upload. There may
                be information on the digital tool that contains errors,
                inaccuracies, or omissions, including descriptions, pricing
                estimates, projections, financial information, and various other
                information. Optony reserves the right to correct any errors,
                inaccuracies, or omissions and to change or update the
                information available on the digital tool at any time, without
                prior notice.
              </p>
              <p>
                Optony shall not be held liable for any direct, indirect,
                incidental, special, consequential, or punitive damages arising
                out of your use of this tool, including but not limited to any
                errors or omissions in any content or data, any loss or damage
                of any kind incurred as a result of the use of any content or
                data uploaded, transmitted, or otherwise made available via this
                tool. Optony will not be liable to you or any third party for
                any modification, suspension, or discontinuance of this digital
                tool. By using this digital tool, you acknowledge and accept the
                inherent risks of data transmission over the internet. We
                reserve the right to modify these terms at any time, without
                prior notice.
              </p>
              <p>
                You are required to provide a digital signature, which will be
                recorded and stored securely, serving as your acknowledgment and
                agreement to these terms and conditions. By clicking
                &ldquo;Accept&rdquo; and providing your digital signature, you
                acknowledge that you have read, understood, and agree to be
                bound by these terms and conditions.
              </p>
            </div>
            <DialogFooter>
              <DialogClose>
                <Button onClick={handleFinalSubmit}>Agree</Button>
              </DialogClose>

              <DialogClose>
                <Button
                  variant="secondary"
                  onClick={() => setShowDialog(false)}
                >
                  Cancel
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default MasterAccessDashboard;
