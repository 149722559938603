import { getAuthenticatedClient } from "../supabaseClient";

// Important: this list needs to exactly match the columns in the fleet data table
export const fleetDataList = [
  "equipment_id",
  "Equipment ID",
  "user_id",
  "description",
  "Replacement Year",
  "Expected Lifetime",
  "Simplified Domicile",
  "EV Purchase Cost pre-incentive",
  "Default Replacement Allocation",
  "EV Annual Maintenance Costs",
  "Existing Vehicle Annual Maintenance",
  "EV Annual Charging Costs",
  "Existing Vehicle Annual Fuel Costs",
  "state_incentives",
  "IRA Incentives",
  "Annual KWh",
  "ghg",
  "electrification_scenario",
  "vehicle_type",
  "charger_assignment",
];

// Important: this list needs to exactly match the columns in the fleet data table
export const phasesDataList = [
  "id",
  "user_id",
  "site",
  "year",
  "ports",
  "loan_amount",
  "port_10_20_kw",
  "port_25_kw",
  "port_180_200_kw",
  "port_less_than_10_kw",
  "trenching_costs",
  "upgrade_cost_utility",
  "upgrade_cost_customer",
  "procurement_management_cost",
  "incentives",
  "capital_planning_funding",
  "estimated_public_works_engineering_costs",
  "master_access_incentives",
  "other_costs",
  "other_costs_description",
];

export const saveDefault = async (userId, tableName) => {
  const supabase = getAuthenticatedClient();
  // fleet data
  // advanced controls
  // phases
  console.log(tableName);
  console.log(userId);
  const { data, error } = await supabase
    .from(tableName)
    .select("*")
    .eq(tableName == "advanced controls" ? "id" : "user_id", userId);

  if (error) {
    return { error: "Failed to fetch data from Supabase" };
  }

  const mapping = {
    "advanced controls": "advanced_controls",
    "fleet data": "fleet_data",
    phases: "infrastructure_phases",
  };
  const field = mapping[tableName];

  const { data: updateData, error: updateError } = await supabase
    .from("default data")
    .update({ [field]: data })
    .eq("id", userId);

  console.log(updateData);
  console.log(updateError);

  if (updateError) {
    return { error: "Failed to update default data in Supabase" };
  }

  return { message: "Default data saved and updated successfully" };
};

export const updateDefault = async (userId, tableName) => {
  const supabase = getAuthenticatedClient();

  try {
    const mapping = {
      "advanced controls": "advanced_controls",
      "fleet data": "fleet_data",
      phases: "infrastructure_phases",
    };

    const { data: jsonData } = await supabase
      .from("default data") // Replace with your actual source table
      .select(mapping[tableName]) // Replace with the actual column containing JSON data
      .eq("id", userId);

    const jsonObject = jsonData[0][mapping[tableName]];

    if (jsonObject === null) {
      return { error: "Default data not found" };
    }

    const updatedJsonObject = jsonObject.map((item) => {
      // Create a new object to store the updated item
      const newItem = {};

      // Iterate over keyList and add the key to newItem if it exists in item, otherwise add it as null
      if (tableName == "fleet data") {
        fleetDataList.forEach((key) => {
          newItem[key] = key in item ? item[key] : null;
        });
      } else if (tableName == "phases") {
        phasesDataList.forEach((key) => {
          if (key === "other_costs_description") {
            newItem[key] = key in item ? item[key] : "";
          } else {
            newItem[key] = key in item ? item[key] : 0;
          }
        });
      } else {
        // If not "fleet data", keep the item as it is
        Object.assign(newItem, item);
      }

      return newItem;
    });

    const delRes = await supabase
      .from(tableName)
      .delete()
      .eq(tableName == "advanced controls" ? "id" : "user_id", userId)
      .select();

    //duty cycle

    const { error } = await supabase
      .from(tableName)
      .insert(updatedJsonObject)
      .select();

    if (error) {
      await supabase.from(tableName).insert(delRes.data);
      throw new Error(`Error inserting data: ${error.message}`);
    }

    return { message: "Default data restored" };
  } catch (error) {
    return { error: error.message };
  }
};
