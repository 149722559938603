/**
 * @fileoverview This component contains the Year Dropdwon for the dashboard.
 */

import React from "react";
import useProForma from "../../store/useProForma";
import { usePostHog } from "posthog-js/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { ScrollArea } from "components/ui/scroll-area";
import useGraphState from "store/useGraphState";

const YearDropdown = () => {
  const posthog = usePostHog();
  const { years, updateEvalYear } = useProForma();
  const { EVAL_YEAR, YEARS } = years;
  const { setHoveredYear } = useGraphState();

  const handleYearChange = (num) => {
    updateEvalYear(num);
    posthog?.capture("Year Changed", { new_value: num });
  };

  // Execute logic when dropdown closes
  const handleDropdownClose = () => {
    setHoveredYear(null);
  };

  return (
    <DropdownMenu
      onOpenChange={(open) => {
        if (open) {
          posthog?.capture("Year Dropdown Opened");
        } else {
          handleDropdownClose();
        }
      }}
      className=""
    >
      <DropdownMenuTrigger asChild>
        <div className="inline-flex items-center text-xl lg:text-sm">
          <div className="text-[#40423f] font-semibold font-['Inter'] leading-normal">
            {EVAL_YEAR && EVAL_YEAR}
          </div>
          <img width={20} src="Keyboard arrow down.svg"></img>
        </div>
      </DropdownMenuTrigger>

      <DropdownMenuContent>
        <ScrollArea className="h-72 w-48 rounded-md">
          <DropdownMenuRadioGroup
            value={YEARS}
            onValueChange={handleYearChange}
          >
            {YEARS?.map((option) => (
              <DropdownMenuRadioItem
                onMouseEnter={() => setHoveredYear(option)}
                onMouseLeave={() => setHoveredYear(null)}
                key={option}
                value={option}
              >
                {option}
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default YearDropdown;
