import { getAuthenticatedClient } from "../supabaseClient";

export const deleteUser = async (userId) => {
  const supabase = getAuthenticatedClient();

  try {
    // Delete from table1
    let { error } = await supabase
      .from("advanced controls")
      .delete()
      .eq("id", userId);
    if (error) throw error;

    // Delete from table2
    ({ error } = await supabase
      .from("charger costs")
      .delete()
      .eq("id", userId));
    if (error) throw error;

    // Delete from table3 (add as many tables as needed)
    ({ error } = await supabase.from("controls").delete().eq("id", userId));
    if (error) throw error;

    ({ error } = await supabase
      .from("fleet data")
      .delete()
      .eq("user_id", userId));
    if (error) throw error;

    ({ error } = await supabase
      .from("user_metadata")
      .delete()
      .eq("id", userId));
    if (error) throw error;

    ({ error } = await supabase
      .from("fleet data")
      .delete()
      .eq("user_id", userId));
    if (error) throw error;

    ({ error } = await supabase.from("default data").delete().eq("id", userId));
    if (error) throw error;

    await supabase.auth.admin.deleteUser(userId);

    return { message: "User data deleted successfully from all tables" };
  } catch (error) {
    console.error("Error deleting user data:", error.message);
    return { error: error.message };
  }
};
