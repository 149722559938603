/**
 * @fileoverview Phases/Chargers outer level component
 */

import React from "react";
import { useState } from "react";
import useAuthStore from "../store/useAuthStore";
import ResetButton from "../components/ResetButton";
import PhaseGrid from "../components/phase-grid/PhaseGrid.js";
import AddPhaseButton from "../components/phase-grid/AddPhaseButton.js";
import { jsonToCsv } from "../utils/jsonToCsv";
import useProForma from "../store/useProForma";
import { Button } from "../components/ui/button";
import ToggleNav from "components/ToggleNav";
import { usePostHog } from "posthog-js/react";

function Phases() {
  const posthog = usePostHog();
  const { user } = useAuthStore();
  const { phases, cityInfo } = useProForma();

  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData] = useState([]);
  const getFormattedDate = () => {
    const date = new Date();
    const yy = date.getFullYear().toString().slice(-2);
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const dd = String(date.getDate()).padStart(2, "0");
    return `${yy}_${mm}_${dd}`;
  };
  const filename = `${
    user?.email.split("@")[0]
  }_EVSE_${getFormattedDate()}.csv`;

  /**
   * @function formatPhaseData - Formats phase data for CSV export
   * @param {Array} phases - Array of phase objects
   * @returns {Array} Formatted phase objects
   */
  const formatPhaseData = (phases) => {
    // Check if master access incentives should be shown
    if (!phases) return [];
    const showMasterAccessIncentives =
      phases.reduce(
        (sum, phase) => sum + (phase.master_access_incentives || 0),
        0
      ) !== 0;
    return phases.map((phase) => {
      const formattedPhase = {
        Site: phase.site,
        Year: phase.year,
        "Equipment Costs": phase.cost,
        "Installation Costs": phase.installCost,
        "Incentives/Rebates/Credits": phase.incentives,
        "Capital Planning Funding": phase.capital_planning_funding,
      };

      if (showMasterAccessIncentives) {
        formattedPhase["Master Access Incentives"] =
          phase.master_access_incentives;
      }

      return {
        ...formattedPhase,
        "Procurement Management Cost": phase.procurement_management_cost,
        "Estimated Public Works Engineering Costs":
          phase.estimated_public_works_engineering_costs,
        "Trenching Costs": phase.trenching_costs,
        "Utility Upgrade Cost": phase.upgrade_cost_utility,
        "Customer Upgrade Cost": phase.upgrade_cost_customer,
        "Other Costs": phase.other_costs,
        "Other Costs Description": phase.other_costs_description,
        "Loan Amount": phase.loan_amount,
        [cityInfo.range_1 ? `Ports ${cityInfo.range_1}` : "Ports <10 kW"]:
          phase.port_less_than_10_kw,
        [cityInfo.range_2 ? `Ports ${cityInfo.range_2}` : "Ports 10-20 kW"]:
          phase.port_10_20_kw,
        [cityInfo.range_3 ? `Ports ${cityInfo.range_3}` : "Ports 40 kW"]:
          phase.port_25_kw,
        [cityInfo.range_4 ? `Ports ${cityInfo.range_4}` : "Ports 180-200 kW"]:
          phase.port_180_200_kw,
      };
    });
  };

  /**
   * @function downloadCsv - Downloads the phases data as a CSV file
   */
  const downloadCsv = () => {
    const formattedData = formatPhaseData(phases.phases);
    const csv = jsonToCsv(formattedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  if (!user) {
    return <div>Please log in</div>;
  }

  return (
    <div className="relative">
      <div className=" flex lg:flex-col lg:mb-4 items-center mb-4">
        <div className="flex items-center w-full justify-between p-5">
          <div>
            <div className="flex justify-between gap-2">
              <ToggleNav />
            </div>
            <div className="text-[#40423f] text-[23px] font-medium font-['Roboto'] leading-7">
              Chargers
            </div>
            <div className="text-[#8a8d89] text-[17px] font-medium font-['Inter'] leading-tight">
              Detailed data about your systems used to inform our advanced
              modeling
            </div>
          </div>
          <div className="bg-white p-4 rounded-full flex gap-2">
            <ResetButton tableName="phases" />
            <Button
              variant="outline"
              onClick={() => {
                downloadCsv();
                posthog.capture("export charger data");
              }}
              className="rounded-full flex gap-2 bg-white"
            >
              <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none flex gap-2 items-center">
                <img src="icons/exportIcon.svg"></img>Export to .csv
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col rounded-3xl gap-6 bg-white p-6 h-full">
        <PhaseGrid
          gridApi={gridApi}
          setGridApi={setGridApi}
          rowData={rowData}
          setRowData={setRowData}
        />
        <AddPhaseButton
          gridApi={gridApi}
          rowData={rowData}
          setRowData={setRowData}
        />
      </div>
    </div>
  );
}

export default Phases;
