/**
 * This is the component for the PortCounts in the dashboard.
 *
 * Child components: PortsHorizontalGraph.js
 *
 * Parent: Dashboard.js
 */

import React, { useState, useEffect } from "react";
import useProForma from "../../store/useProForma";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  LabelList,
} from "recharts";
import { axisHelper } from "../../utils/graphs/axisHelper";
import { axisStyle, labelStyle } from "./styles";
import { roundToNearest } from "utils/graphs/roundNumbers";
import PortsHorizontalGraph from "./graphs/PortsHorizontalGraph";
import { PRIMARY_COLOR, SECONDARY_GRAPH_COLOR } from "colors";
import CustomPortsTooltip from "./graphs/CustomPortsTooltip";

const colorMap = {
  0: `${SECONDARY_GRAPH_COLOR}`,
  1: `${PRIMARY_COLOR}`,
  2: "#6f95ef",
  3: "#b5625b",
  4: "#db8954",
  5: "#efd844",
  6: "#57b3cc",
  7: "#c87fbd",
  8: "#ff9a76",
  9: "#98d98e",
  10: "#c6538c",
  11: "#5da6a7",
  12: "#a3e5c0",
  13: "#755d9b",
  14: "#f49580",
  15: "#ebc155",
  16: "#6382bf",
  17: "#f37d6a",
  18: "#a9d66f",
  19: "#b37cb5",
  20: "#6f8bbd",
  21: "#f2a541",
  22: "#70964b",
  23: "#e06179",
  24: "#ffbe67",
  25: "#c5d583",
  26: "#4db6ac",
  27: "#d65db1",
  28: "#ff7043",
  29: "#64b5f6",
  30: "#9ccc65",
  31: "#ba68c8",
  32: "#4fc3f7",
  33: "#ffa726",
  34: "#7986cb",
  35: "#aed581",
  36: "#ff8a65",
  37: "#4dd0e1",
  38: "#a1887f",
  39: "#9575cd",
  40: "#f06292",
  41: "#7cb342",
  42: "#ffb74d",
  43: "#5c6bc0",
  44: "#8d6e63",
  45: "#ff7e79",
  46: "#78909c",
  47: "#9fa8da",
  48: "#80deea",
  49: "#bcaaa4",
  50: "#ce93d8",
};
const calculateSum = (values) => {
  return Object.values(values).reduce((acc, curr) => acc + curr, 0);
};

const PortCounts = () => {
  // Your component logic goes here
  let totalCount = 0;
  const [incentiveSum, setIncentiveSum] = useState(0);
  const [evseCostDifference, setEvseCostDifference] = useState(0);
  const {
    phases = {},
    controls = {},
    allSitesYearOverYear = {},
  } = useProForma();
  const {
    chargerIncentives = {},
    totalChargingInfrastructureCosts = {},
    totalChargingInfrastructureSavings = 0,
  } = allSitesYearOverYear;
  const phaseData = phases?.phases || [];
  const uniqueYears = [...new Set(phaseData.map((item) => item.year))];
  const uniqueSites = controls?.domiciles;
  const yearSiteObject = uniqueYears.reduce((acc, year) => {
    acc[year] = uniqueSites.reduce((siteAcc, site) => {
      siteAcc[site] = 0;
      return siteAcc;
    }, {});
    return acc;
  }, {});

  useEffect(() => {
    calculateSum(chargerIncentives);
    setIncentiveSum(calculateSum(chargerIncentives));
    setEvseCostDifference(
      calculateSum(totalChargingInfrastructureCosts) -
        calculateSum(totalChargingInfrastructureSavings)
    );
  }, [
    chargerIncentives,
    totalChargingInfrastructureCosts,
    totalChargingInfrastructureSavings,
  ]);

  phaseData.forEach((phase) => {
    if (
      phase.year in yearSiteObject &&
      phase.site in yearSiteObject[phase.year]
    ) {
      let count =
        phase.port_10_20_kw +
        phase.port_25_kw +
        phase.port_180_200_kw +
        phase.port_less_than_10_kw;
      yearSiteObject[phase.year][phase.site] += count;
      totalCount += count;
    }
  });

  const data = Object.entries(yearSiteObject).map(([year, sites]) => {
    const entry = { year, total: 0 };
    Object.entries(sites).forEach(([site, count]) => {
      entry[site] = count;
      entry.total += count;
    });
    return entry;
  });

  let max = 0;
  data.forEach((entry) => {
    let tempCount = 0;
    controls?.domiciles.forEach((site) => {
      tempCount += entry[site];
    });
    if (tempCount > max) {
      max = tempCount;
    }
  });

  return (
    <div className="bg-white rounded-2xl p-4 flex flex-col gap-6 border">
      <div>
        <div className="flex gap-2 text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
          <img src="icons/portsicon.svg" width="20"></img>Ports
        </div>
      </div>
      <div className="mt-0 flex flex-col-reverse gap-4">
        <div className="flex justify-start gap-6">
          <div>
            <div className="flex items-end">
              <div className="text-[#1b1c1b] text-[29px] font-semibold font-['Inter'] leading-[34.80px]">
                {totalCount}
              </div>
              <div className="text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
                Ports
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Total ports to be installed
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-between gap-6">
          <div className="w-1/2">
            <div
              className={`flex items-end ${
                Math.round(incentiveSum) === 0
                  ? "text-[#1b1c1b]"
                  : Math.round(incentiveSum) < 0
                  ? "text-[#1b1c1b]"
                  : "text-[#008000]"
              }`}
            >
              <div className="text-xl font-medium font-['Inter'] leading-normal">
                $
              </div>
              <div className="text-[29px] font-semibold font-['Inter'] leading-[34.80px]">
                {roundToNearest(incentiveSum).val}
              </div>
              <div className="text-xl font-medium font-['Inter'] leading-normal">
                {roundToNearest(incentiveSum).suffix}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Total charger incentive value
            </div>
          </div>
          <div className="w-1/2">
            <div className={`flex items-end text-[#1b1c1b]`}>
              <div className="text-xl font-medium font-['Inter'] leading-normal">
                $
              </div>
              <div className="text-[29px] font-semibold font-['Inter'] leading-[34.80px]">
                {roundToNearest(evseCostDifference).val}
              </div>
              <div className="text-xl font-medium font-['Inter'] leading-normal">
                {roundToNearest(evseCostDifference).suffix}
              </div>
            </div>
            <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">
              Total EVSE Costs
            </div>
          </div>
        </div>
      </div>
      <PortsHorizontalGraph />

      <ResponsiveContainer width="100%" height={300}>
        <BarChart width={500} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" style={axisStyle} stroke="#7E817D" />
          <YAxis
            stroke="#7E817D"
            style={axisStyle}
            domain={[0, Math.ceil((max * 1.1) / 4) * 4]}
            ticks={axisHelper(0, Math.ceil((max * 1.1) / 4) * 4, 5)}
          >
            <Label
              value="Ports"
              angle={-90}
              offset={30}
              position="insideLeft"
              style={labelStyle}
            />
          </YAxis>
          <Tooltip content={<CustomPortsTooltip />} />
          <Legend align="right" wrapperStyle={{ fontSize: "12px" }} />
          {uniqueSites?.map((site, index) => (
            <Bar
              radius={[5, 5, 5, 5]}
              key={site}
              dataKey={site}
              stackId="a"
              fill={`${colorMap[index]}`}
            >
              {index === uniqueSites.length - 1 && (
                <LabelList
                  style={{
                    textAlign: "center",
                    color: "#40423f",
                    fontSize: "13px",
                    fontWeight: "normal",
                    fontFamily: "Roboto",
                    lineHeight: "1",
                  }}
                  dataKey="total"
                  position="top"
                />
              )}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PortCounts;
