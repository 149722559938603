import React from "react";
import { NavLink } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import PropTypes from "prop-types";

const NavItem = ({ to, icon: Icon, label, isOpen }) => {
  const posthog = usePostHog();

  const handleClick = () => {
    posthog?.capture(`${label} navigate`);
  };

  return (
    <NavLink to={to} onClick={handleClick}>
      {({ isActive }) => (
        <li
          className={`
            flex items-center rounded-xl py-3 text-sm font-medium mb-2
            ${isOpen ? "px-4" : "h-11 w-11"}
            ${
              isActive
                ? "bg-black text-white"
                : "hover:bg-[#e4e6e4] active:bg-[#d7d9d7]"
            }
          `}
        >
          <Icon
            className={isOpen ? "mr-2" : "m-auto"}
            width={label === "Dashboard" ? "20" : "18"}
            height={label === "Dashboard" ? "20" : "18"}
          />
          {isOpen && label}
        </li>
      )}
    </NavLink>
  );
};

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default NavItem;
