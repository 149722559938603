/**
 * @fileoverview This is the component for the label for each control in the advanced controls section.
 * 
 * @params text, info -> text is the label for the control and info is the tooltip information.
 * 
 * If there is no tooltip information, it will just display the text.
 * 
 * Parent components in Economics.js and SoftwareCosts.js
 */


import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { MdInfoOutline } from "react-icons/md";
import { Label } from "components/ui/label";
import React from "react";
import PropTypes from 'prop-types';

const ControlLabel = ({ text, info }) => {

  if (info===""){
    return <div className="text-[#585b58] text-sm font-normal font-['Roboto'] leading-[16.80px]">{text}</div>
  }
  return (
    <TooltipProvider className="">
      <Tooltip className="">
        <Label className="w-full">
          <div className="w-full flex gap-2 relative">
            <div className="text-[#585b58] text-sm font-normal font-['Roboto'] leading-[16.80px]">{text}</div>
            <TooltipTrigger asChild>
              <button>
                <MdInfoOutline size={15} />
              </button>
            </TooltipTrigger>
           
          </div>
        </Label>
        <TooltipContent style={{ zIndex: 9999 }}>
          <p style={{ zIndex: 9999 }}>{info}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

ControlLabel.propTypes = {
  text: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired
};

export default ControlLabel;
