import { getAuthenticatedClient } from "../supabaseClient";
import { fleetDataList } from "./defaultapi";

function cleanHeader(header) {
  return header.replace(/^\uFEFF/, "").trim(); // Remove BOM if present and trim whitespace/control chars
}

const parseCSV = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const csvData = event.target.result;
      const lines = csvData.split("\n");

      const headers = lines[0].split(",").map(cleanHeader);

      const results = [];
      for (let i = 1; i < lines.length; i++) {
        if (!lines[i].trim()) continue; // Skip empty lines

        const values = lines[i].split(",");
        const row = {};

        headers.forEach((header, index) => {
          const cleanedHeader = header.trim(); // Ensure header is clean
          row[cleanedHeader] = values[index] ? values[index].trim() : "";
        });

        results.push(row);
      }

      resolve({ headers, results });
    };

    reader.onerror = (error) => {
      console.error(`Error reading CSV file: ${error}`);
      reject(error);
    };
    reader.readAsText(file);
  });
};

const uploadFile = async (formdata, deleteData = false, setDefault = true) => {
  const supabase = getAuthenticatedClient();
  const file = formdata.get("file");
  const userId = formdata.get("userId");

  if (!file) {
    return { error: "No file uploaded." };
  }

  try {
    const { results } = await parseCSV(file);

    for (const item of results) {
      item.user_id = userId;
      let obj = {};
      Object.keys(item).forEach((key) => {
        if (item[key] === "") {
          item[key] = 0;
          if (key === "vehicle_type") {
            item[key] = "";
          }
        }
        if (key.startsWith("ES: ")) {
          obj[key.replace("ES: ", "")] = item[key] === "1" ? true : false;
          delete item[key];
        }
      });
      item.electrification_scenario = obj;
    }

    const jsonData = JSON.stringify(results);
    const jsonObject = JSON.parse(jsonData);

    let obj = jsonObject[0];
    let errors = [];

    fleetDataList.forEach((key) => {
      // Check if the key exists in the object, accounting for potential whitespace/control chars
      const keyExists = Object.keys(obj).some(
        (objKey) =>
          objKey.trim() === key.trim() || objKey.replace(/\r$/, "") === key
      );

      if (!keyExists && key !== "equipment_id") {
        errors.push(`missing key ${key}`);
      }
    });

    if (errors.length > 0) {
      return { error: errors };
    }

    if (deleteData) {
      const { error: deleteError } = await supabase
        .from("fleet data")
        .delete()
        .eq("user_id", userId)
        .select();
      if (deleteError) {
        return { error: deleteError.message };
      }
    }

    const updatedJsonObject = jsonObject.map((item) => {
      // Create a new object to store the updated item
      const newItem = {};

      // Iterate over keyList and add the key to newItem if it exists in item, otherwise add it as null
      fleetDataList.forEach((key) => {
        if (key !== "equipment_id") {
          // Find the matching key in the item, accounting for whitespace/control chars
          const matchingKey = Object.keys(item).find(
            (itemKey) =>
              itemKey.trim() === key.trim() ||
              itemKey.replace(/\r$/, "") === key
          );

          newItem[key] = matchingKey ? item[matchingKey] : null;
        }
      });
      return newItem;
    });

    const { data, error } = await supabase
      .from("fleet data")
      .insert(updatedJsonObject)
      .select();

    if (error) {
      return { error: error.message };
    }

    if (data?.length === 0) {
      return { error: "Error uploading file" };
    }

    if (setDefault) {
      await supabase
        .from("default data")
        .insert({ id: userId, fleet_data: data });
    }

    return { message: "File uploaded successfully!" };
  } catch (error) {
    return { error: error.message || "Error processing CSV file" };
  }
};

export const uploadWithDelete = async (formdata) => {
  return uploadFile(formdata, true, false);
};

export const uploadWithOutDelete = async (formdata) => {
  return uploadFile(formdata, false, true);
};
