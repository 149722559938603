/**
 * This is the component for the cash flow graph displayed in the dashboard.
 * 
 * Parent: Dashboard.js
 */

import React, { useState } from "react";
import useProForma from "../../store/useProForma";

const formatCurrency = (value) => {
  // Check if the value is exactly 0 or rounds to 0
  if (value === 0 || Math.round(value) === 0) {
    return "";
  }

  // Round the value to the nearest integer
  const roundedValue = Math.round(value);

  const absValue = Math.abs(roundedValue)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return roundedValue < 0 ? `${absValue}` : `${absValue}`;
};

const formatDollar = (value) => {
  if (value < 0) {
    return "-$";
  } else if (value > 0) {
    return "$";
  } else {
    return "$-";
  }
};

const CashFlow = () => {
  const { yearOverYear, years } = useProForma();
  const { annualCostBenefit = {} } = yearOverYear;
  const { EVAL_YEAR } = years;

  const [cashFlowValue, setCashFlowValue] = useState(annualCostBenefit[EVAL_YEAR]);

  React.useEffect(() => {
    setCashFlowValue(annualCostBenefit[EVAL_YEAR]);
  }, [annualCostBenefit, EVAL_YEAR]);

  return (
    <div className="bg-white rounded-2xl p-4 flex flex-col gap-5 border">
      <div className="">
        <div className="flex gap-2 text-[#1b1c1b] text-xl font-medium font-['Inter'] leading-normal">
          <img src="Finance chip.svg" width="20"></img>Cash Flow
        </div>
      </div>

      <div className="flex justify-between ">
        <div className="">
          <div
            className={` text-[29px] font-semibold font-['Inter'] leading-[34.80px] ${
              Math.round(cashFlowValue) === 0
                ? "text-black"
                : Math.round(cashFlowValue) < 0
                ? "text-black"
                : "text-[#008000]"
            }`}
          >
            <span className="text-xl font-medium">{formatDollar(cashFlowValue)}</span>
            {(cashFlowValue || cashFlowValue !== 0) && formatCurrency(cashFlowValue)}
          </div>
          <div className="text-[#7e817d] text-[13px] font-normal font-['Roboto'] leading-none">Estimated cash flow, {EVAL_YEAR}</div>
        </div>
      </div>
    </div>
  );
};

export default CashFlow;
