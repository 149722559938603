import { getAuthenticatedClient } from "../supabaseClient";

/**
 * @function addAdvancedControl - Add advanced control to the database, called when first creating account
 *
 * @param {Object} defaultAdvancedControls default advanced controls to be created
 * @returns {Promise<Object>} The result of the database operation
 */
export const addAdvancedControl = async (defaultAdvancedControls) => {
  const {
    id,
    inflation,
    inflation_escalation_rate,
    electricity_escalation_rate,
    gasoline_escalation_rate,
    infrastructure_loan_term,
    infrastructure_loan_interest_rate,
    discount_rate_npv,
    maintenance_costs_annual_per_station,
    charging_optimization,
    charging_optimization_savings,
    charge_management_subscription_costs,
    charger_network_costs,
  } = defaultAdvancedControls;

  const supabase = getAuthenticatedClient();

  const { data, error } = await supabase.from("advanced controls").insert([
    {
      id,
      inflation,
      inflation_escalation_rate,
      electricity_escalation_rate,
      gasoline_escalation_rate,
      infrastructure_loan_term,
      infrastructure_loan_interest_rate,
      discount_rate_npv,
      maintenance_costs_annual_per_station,
      charging_optimization,
      charging_optimization_savings,
      charge_management_subscription_costs,
      charger_network_costs,
    },
  ]);

  return { data, error };
};

export const patchAdvancedControl = async (id, updates) => {
  const supabase = getAuthenticatedClient();
  if (!id) {
    return { error: "ID is required" };
  }

  const updateFields = {};

  // List of fields that can be updated
  const fields = [
    "inflation",
    "inflation_escalation_rate",
    "electricity_escalation_rate",
    "gasoline_escalation_rate",
    "infrastructure_loan_term",
    "infrastructure_loan_interest_rate",
    "discount_rate_npv",
    "maintenance_costs_annual_per_station",
    "charging_optimization",
    "charging_optimization_savings",
    "charge_management_subscription_costs",
    "charger_network_costs",
  ];

  // Dynamically add fields to the update object if they exist in the updates parameter
  fields.forEach((field) => {
    if (updates && updates[field] !== undefined) {
      updateFields[field] = updates[field];
    }
  });

  if (Object.keys(updateFields).length === 0) {
    return { error: "No valid fields to update" };
  }

  try {
    const { data, error } = await supabase
      .from("advanced controls")
      .update(updateFields)
      .eq("id", id)
      .select();

    if (error) {
      return { error: error };
    }

    return { data: data };
  } catch (error) {
    console.error("Error updating advanced control:", error);
    return { error: "An unexpected error occurred" };
  }
};
