/**
 * API functions for fleet data (AI Generated)
 */
import { getAuthenticatedClient } from "../supabaseClient";

export const patchFleet = async (body) => {
  const supabase = getAuthenticatedClient();
  const { equipment_id } = body;
  const updateFields = {};

  // List of fields that can be updated
  const fields = [
    "Equipment ID",
    "Replacement Year",
    "Expected Lifetime",
    "EV Purchase Cost pre-incentive",
    "Default Replacement Allocation",
    "state_incentives",
    "IRA Incentives",
    "vehicle_candidate",
    "icev_candidate",
    "Simplified Domicile",
    "description",
    "vehicle_type",
    "charger_assignment",
  ];

  // Dynamically add fields to the update object if they exist in the request body
  fields.forEach((field) => {
    if (body[field] !== undefined) {
      updateFields[field] = body[field];
    }
  });

  if (Object.keys(updateFields).length === 0) {
    return { error: "No valid fields to update" };
  }

  try {
    const { data, error } = await supabase
      .from("fleet data")
      .update(updateFields)
      .eq("equipment_id", equipment_id)
      .select();

    if (error) {
      return { error: error };
    }

    return data;
  } catch (err) {
    console.error("Error updating fleet data:", err);
    return { error: "An unexpected error occurred" };
  }
};

export const deleteVehicle = async (id) => {
  const supabase = getAuthenticatedClient();

  const response = await supabase
    .from("fleet data")
    .delete()
    .eq("equipment_id", id);

  if (response.error) {
    return { error: response.error };
  }

  return response;
};
