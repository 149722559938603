/**
 * This is the component for the electrification scenario dropdown in the dashboard.
 *
 * Parent: Dashboard.js
 */

/* global process */
import React, { useEffect, useState } from "react";
import useAuthStore from "../../store/useAuthStore";
import useCache from "../../store/useCache";
import useProForma from "../../store/useProForma";
import { usePostHog } from "posthog-js/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { updateControlsNew } from "../../api/controlsapi";
const ElectrificationScenarioDropdown = () => {
  const { user } = useAuthStore();
  const posthog = usePostHog();
  const { controls, fleetData } = useProForma();
  const { updateControls } = useCache();
  const [electrificationOptions, setElectrificationOptions] = useState([]);
  const [electrificationScenario, setElectrificationScenario] = useState("");

  useEffect(() => {
    if (fleetData && fleetData[0] && fleetData[0].electrification_scenario) {
      const options = Object.keys(fleetData[0].electrification_scenario);
      setElectrificationOptions(options);
    }
  }, [fleetData]);

  useEffect(() => {
    if (controls.domiciles) {
      setElectrificationScenario(controls["electrification_scenario"]);
    }
  }, [controls, fleetData]);

  const updateControl = async (attribute, value) => {
    if (value === "" || value === null) return;
    try {
      updateControls(attribute, value);
      const response = await updateControlsNew(user.id, attribute, value);

      if (response.error) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error(`Error updating control: ${error.message}`);
    }
  };

  const handleElectrificationScenarioChange = (str) => {
    if (str === "") {
      return;
    }
    const newValue = str;
    setElectrificationScenario(newValue);
    updateControl("electrification_scenario", newValue);
    posthog?.capture("Electrification Scenario Changed", {
      new_value: newValue,
    });
  };
  return (
    <div className="max-w-full">
      <DropdownMenu
        className=""
        onOpenChange={(open) =>
          open && posthog?.capture("Electrification Scenario Dropdown Opened")
        }
      >
        <DropdownMenuTrigger asChild>
          <div className="inline-flex items-center w-full text-xl lg:text-sm">
            <div className="text-[#40423f] font-semibold font-['Inter'] leading-normal w-full truncate overflow-hidden">
              {electrificationScenario}
            </div>
            <img width={20} src="Keyboard arrow down.svg"></img>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuRadioGroup
            value={electrificationOptions}
            onValueChange={handleElectrificationScenarioChange}
          >
            {electrificationOptions?.map((option) => (
              <DropdownMenuRadioItem key={option} value={option}>
                {option}
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ElectrificationScenarioDropdown;
