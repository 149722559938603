/**
 * @fileoverview API functions for user metadata
 *
 */

import { getAuthenticatedClient } from "../supabaseClient";

const fetchUserMetadata = async (userId) => {
  const supabase = getAuthenticatedClient();
  try {
    const { data, error } = await supabase
      .from("user_metadata")
      .select("*")
      .eq("id", userId);

    if (error) {
      return { error: error.message };
    }

    if (data.length === 0) {
      // If it doesn't exist, create a new entry
      const newCityRes = await supabase
        .from("user_metadata")
        .insert([{ id: userId, first_login: false }]) // Adjust the fields as necessary
        .select("*");
      if (newCityRes.error) {
        return { error: newCityRes.error.message };
      }

      return newCityRes.data; // Return the newly created entry
    }
    return data; // Return the updated entry
  } catch (error) {
    console.error("Error fetching user metadata:", error);
    return { error: "An unexpected error occurred" };
  }
};

export const createUserMetadata = async (userId) => {
  const response = await fetchUserMetadata(userId);
  return response;
};

export const getUserMetadata = async (userId) => {
  const response = await fetchUserMetadata(userId);
  return response;
};

export const updateMetadata = async (userId, metadata) => {
  const updates = metadata;
  const supabase = getAuthenticatedClient();

  try {
    const { data, error } = await supabase
      .from("user_metadata")
      .update(updates)
      .eq("id", userId)
      .select("*");

    if (error) {
      return { error: error.message };
    }

    if (data.length === 0) {
      return { error: "User metadata not found" };
    }

    return data; // Return the updated entry
  } catch (error) {
    console.error("Error updating user metadata:", error);
    return { error: "An unexpected error occurred" };
  }
};
