/**
 * Admin level recover user page
 */

import React, { useState } from "react";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../components/ui/card";
import { adminFetchUser } from "../api/adminapi";

const AdminRecoverUser = () => {
  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState("");
  const [messageStatus, setMessageStatus] = useState("success");

  /**
   * @function handleFetchUser - Fetches the user with the given username
   */
  const handleFetchUser = async (e) => {
    e.preventDefault();
    const link = await adminFetchUser(userId);
    if (!link) {
      setMessageStatus("error");
      setMessage("Requested user not found");
      return;
    }
    setMessageStatus("success");
    setMessage(link);
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      <Card className="min-h-[300px] w-[400px] p-4">
        <CardHeader className="pb-5">
          <CardTitle>Recover User</CardTitle>
          <CardDescription>
            Enter the username to obtain recovery link
          </CardDescription>
          {message &&
            (messageStatus === "success" ? (
              <p className="text-sm break-words">
                <a
                  href={message}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 underline"
                >
                  {message}
                </a>
              </p>
            ) : (
              <p className="text-sm text-red-500 break-words">{message}</p>
            ))}
        </CardHeader>
        <CardContent>
          <form onSubmit={handleFetchUser}>
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-1.5">
                <Label>Username</Label>
                <Input
                  type="text"
                  placeholder="Username"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  required
                />
              </div>
              <div className="flex flex-col space-y-1.5">
                <Button className="relative top-2">Fetch User</Button>
              </div>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default AdminRecoverUser;
