/**
 * @fileoverview Reset button component for resetting the data to default for fleet, advanced controls, and phases
 *
 * Props: takes in table name and button type. Table name is the name of the table to reset to default, and button type is the type of button to display (light or dark)
 */
import React from "react";
import { Button } from "../components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog";
import { Label } from "../components/ui/label";
import useAuthStore from "../store/useAuthStore";
import useProForma from "../store/useProForma";
import { usePostHog } from "posthog-js/react";
import { updateDefault } from "../api/defaultapi";
function ResetButton({ tableName, buttonType }) {
  const { fetchAndUpdateFleet } = useProForma();
  const { user } = useAuthStore();
  const posthog = usePostHog();

  /**
   * @function handleReset - Resets the data to default for the given table name prop
   */

  const handleReset = async () => {
    if (!user) {
      return;
    }

    // Capture reset event in PostHog
    posthog.capture(`reset to default: ${tableName}`);

    try {
      const response = await updateDefault(user.id, tableName);

      if (response.error) {
        throw new Error(response.error);
      }

      // API call successful, fetch the updated data
      await fetchAndUpdateFleet();
    } catch (error) {
      // error handling for api call
      console.error("Error resetting to default:", error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        {buttonType === "light" ? (
          <Button
            variant="outline"
            className="h-[32px] text-[#7e817d] text-xs font-normal font-['Roboto'] leading-[14.40px] flex gap-2 px-3"
          >
            <img src="icons/resetIcon.svg"></img>Reset to default
          </Button>
        ) : (
          <Button
            variant="outline"
            className="rounded-full flex gap-2 bg-black text-white relative hover:bg-[#444]"
          >
            <img src="icons/resetToDefault.svg"></img>
            <div className="text-center text-[#ebebeb] text-[13px] font-normal font-['Roboto'] leading-none">
              Reset to default
            </div>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Are you sure you want to do this?</DialogTitle>
          <DialogDescription>
            Resetting to default will lose all changes.
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-2">
            <Label htmlFor="link" className="sr-only">
              Link
            </Label>
          </div>
        </div>
        <DialogFooter className="">
          <DialogClose asChild>
            <Button className="" onClick={handleReset} type="submit">
              Reset
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export default ResetButton;
