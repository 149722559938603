import supabase from "../supabaseClient";

//requires service role key, TODO: create supabase edge function for this
export const fetchMasterAccess = async (access_code) => {
  try {
    if (!access_code) return;
    const response = await fetch(
      `https://jcvmwzualzmymhcghnro.supabase.co/functions/v1/fetch-master-access/${access_code}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_ANON}`,
        },
      }
    );
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching city info:", error);
  }
};

export const fetchMasterAccessData = async (username) => {
  username = username.toLowerCase();

  const { data, error } = await supabase
    .from("master_access_table")
    .select("*")
    .contains("city_access", [username]);

  if (error) {
    return { error: error.message };
  }

  if (
    !data ||
    !data[0] ||
    !data[0].organization_name ||
    !data[0].contact_link ||
    !data[0].image_url
  ) {
    return { error: "Required data not found or incomplete." };
  }

  return {
    organization_name: data[0].organization_name,
    contact_link: data[0].contact_link,
    image_url: data[0].image_url,
    first_login: data[0].first_login,
    incentive_column_title: data[0].incentive_column_title,
  };
};

export const fetchMasterAccessByCode = async (access_code) => {
  const { data, error } = await supabase
    .from("master_access_table")
    .select("*")
    .eq("access_code", access_code);

  if (error) {
    return { error: error.message };
  }

  if (
    !data ||
    !data[0] ||
    !data[0].organization_name ||
    !data[0].contact_link ||
    !data[0].image_url
  ) {
    return { error: "Required data not found or incomplete." };
  }

  return {
    organization_name: data[0].organization_name,
    contact_link: data[0].contact_link,
    image_url: data[0].image_url,
    first_login: data[0].first_login,
    incentive_column_title: data[0].incentive_column_title,
  };
};

export const updateFirstLogin = async (access_code) => {
  const { data, error } = await supabase
    .from("master_access_table")
    .update({ first_login: false })
    .eq("access_code", access_code)
    .select();

  if (error) {
    return { error: error.message };
  }

  if (!data || data.length === 0) {
    return { error: "Access code not found" };
  }

  return {
    message: "Successfully updated first login status",
    data: data[0],
  };
};
