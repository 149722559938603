/**
 * This is the component for the site dropdown in the dashboard.
 *
 * Parent: Dashboard.js
 */

/* global process */
import React, { useEffect, useState } from "react";
import useAuthStore from "../../store/useAuthStore";
import useCache from "../../store/useCache";
import useProForma from "../../store/useProForma";
import { usePostHog } from "posthog-js/react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { updateControlsNew } from "../../api/controlsapi";
const SiteDropdown = () => {
  const { user } = useAuthStore();
  const posthog = usePostHog();
  const { controls, fleetData } = useProForma();
  const { updateControls } = useCache();
  const [siteOptions, setSiteOptions] = useState([]);
  const [site, setSite] = useState("");

  useEffect(() => {
    let tempSites = [];
    if (controls.domiciles) {
      if (controls.domiciles.length > 1) {
        tempSites = ["All Sites", ...controls.domiciles];
      } else {
        tempSites = controls.domiciles;
      }

      setSite(controls["site"] || "");
      setSiteOptions(tempSites || "");
    }
  }, [controls, fleetData]);

  const updateControl = async (attribute, value) => {
    if (value === "" || value === null) return;
    try {
      updateControls(attribute, value);
      const response = await updateControlsNew(user.id, attribute, value);

      if (response.error) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error(`Error updating control: ${error.message}`);
    }
  };

  const handleSiteChange = (str) => {
    const newValue = str;
    setSite(newValue);
    updateControl("site", newValue);
    posthog?.capture("Site Changed", { new_value: newValue });
  };

  return (
    <div className="max-w-full">
      <DropdownMenu
        onOpenChange={(open) =>
          open && posthog?.capture("Site Dropdown Opened")
        }
      >
        <DropdownMenuTrigger className="" asChild>
          <div className="inline-flex w-full items-center text-xl lg:text-sm">
            <div className="text-[#40423f] font-semibold font-['Inter'] leading-normal w-full truncate overflow-hidden">
              {controls && controls.site}
            </div>
            <img width={20} src="Keyboard arrow down.svg"></img>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="max-h-[400px] overflow-y-auto">
          <DropdownMenuRadioGroup value={site} onValueChange={handleSiteChange}>
            {siteOptions?.map((option) => (
              <DropdownMenuRadioItem key={option} value={option}>
                {option}
              </DropdownMenuRadioItem>
            ))}
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default SiteDropdown;
