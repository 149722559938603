/**
 * @fileoverview Component for toggling the navigation bar (this is the little arrow icon on the top of the page)
 *
 * calls toggleNav, which updates the state of the navigation bar in the zustand state
 */

import React from "react";
import useNavState from "store/useNavState";
import { useMediaQuery } from "react-responsive";

const ToggleNav = () => {
  const { toggleNav, isOpen } = useNavState();
  const isMobile = useMediaQuery({ query: "(max-width: 640px)" });

  return (
    <>
      {!isMobile && (
        <button className="hover:bg-[#e4e6e4] rounded-lg" onClick={toggleNav}>
          <img
            src="navCollapse.svg"
            className={`pt-[2px] transition-transform duration-200 ${
              isOpen ? "" : "rotate-180"
            }`}
          />
        </button>
      )}
    </>
  );
};

export default ToggleNav;
