import { getAuthenticatedClient } from "../supabaseClient";

// POST request to add a login timestamp
export const postLoginTimestamp = async (username, user_id) => {
  const supabase = getAuthenticatedClient();
  try {
    const { error } = await supabase.from("login_timestamps").insert([
      {
        username,
        user_id,
      },
    ]);

    if (error) {
      throw error;
    }
  } catch (error) {
    return { error: error.message };
  }
  return { message: "success" };
};
