/**
 * @fileoverview Function for calling api for phases and updating phases
 *
 * Note: this does not really need to be a zustand state and should likely be in the api folder
 */

import { create } from "zustand";
import { addPhase } from "../api/phaseApi";

const usePhases = create((set) => ({
  phases: [],
  filteredPhases: [],
  updatePhase: async (updatedPhase) => {
    set((state) => ({
      phases: state.phases.map((phase) =>
        phase.id === updatedPhase.id ? { ...phase, ...updatedPhase } : phase
      ),
    }));
  },

  /**
   * @function addPhase - API request to add a phase
   * @param {*} phase
   */
  addPhase: async (phase) => {
    try {
      const response = await addPhase(phase);
      if (response.error) {
        throw new Error("Failed to add phase");
      }
      const newPhase = response;
      set((state) => ({ phases: [...state.phases, newPhase] }));
    } catch (error) {
      console.error(error);
    }
  },
  removePhase: (phaseId) => {
    set((state) => ({
      phases: state.phases.filter((phase) => phase.id !== phaseId),
    }));
  },
  editPhase: (newPhase) => {
    set((state) => ({
      phases: state.phases.map((phase) =>
        phase.id === newPhase.id ? { ...phase, ...newPhase } : phase
      ),
    }));
  },
}));

export default usePhases;
