import React from "react";

const CustomPortsTooltip = ({ active, payload, label }) => {
  if (!active || !payload || !payload.length) return null;

  const nonZeroPayloads = payload.filter((entry) => entry.value > 0);

  return (
    <div
      className="p-4 rounded-lg shadow-lg border border-gray-200 z-50"
      style={{ backgroundColor: "rgba(211, 211, 211, 0.8)" }}
    >
      <p className="font-semibold text-gray-900 mb-2">{label}</p>
      {nonZeroPayloads.map((entry, index) => (
        <div
          key={index}
          className="flex items-center space-x-2 mb-1 flex-nowrap"
        >
          <div
            className="w-3 h-3 rounded-full"
            style={{ backgroundColor: entry.color }}
          />
          <span className="text-gray-700 truncate">{entry.name}:</span>
          <span className="font-medium">{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

export default CustomPortsTooltip;
