/**
 * @fileoverview Navbar component, contains styles for both being open or closed
 */

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "./ui/button";
import { useNavigate, useLocation } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import {
  Pencil2Icon,
  CalendarIcon,
  BarChartIcon,
  DesktopIcon,
  ExitIcon,
} from "@radix-ui/react-icons";
import useAuthStore from "../store/useAuthStore";
import useNavState from "../store/useNavState";
import { Avatar, AvatarFallback, AvatarImage } from "components/ui/avatar";
import useProForma from "store/useProForma";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useMediaQuery } from "react-responsive";
import {
  fetchMasterAccessData,
  fetchMasterAccessByCode,
} from "api/masteraccessapi";
import NavItem from "./navbar/NavItem";
import PropTypes from "prop-types";
import LearnMorePromoCard from "./navbar/LearnMorePromoCard";
import ContactPromoCard from "./navbar/ContactPromoCard";

const Navbar = ({ showLinks = true }) => {
  // variable declarations
  const { logout } = useAuthStore();
  const { isOpen, toggleNav, setNav } = useNavState();
  const { cityInfo } = useProForma();
  const [username, setUsername] = useState("");
  const { user } = useAuthStore();
  const [parentData, setParentData] = useState(null);
  const navigate = useNavigate();
  const posthog = usePostHog();

  // master access variables
  const [cityAccess, setCityAccess] = useState(null);
  const location = useLocation();
  const { code } = location.state || {};

  const navItems = [
    { to: "/", icon: DesktopIcon, label: "Dashboard" },
    { to: "/trends", icon: BarChartIcon, label: "Trends" },
    { to: "/chargers", icon: CalendarIcon, label: "Chargers" },
    { to: "/fleet", icon: Pencil2Icon, label: "Fleet" },
  ];

  useEffect(() => {
    if (!code) {
      return;
    }

    const fetchData = async () => {
      const data = await fetchMasterAccessByCode(code);
      setCityAccess(data);
    };

    fetchData(); // Call the async function
  }, [code, navigate]);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setScreenHeight(window.innerHeight);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = async () => {
    posthog?.capture("User logout");
    await logout();
    navigate("/login");
  };

  /**
   * @function useEffect - Gets the username based on the user's email
   */
  useEffect(() => {
    if (!user) {
      return;
    }
    setUsername(user.email.split("@")[0]);
  }, [user]);

  useEffect(() => {
    if (!username) {
      return;
    }
    const fetchData = async () => {
      const data = await fetchMasterAccessData(username);
      if (data) {
        setParentData(data);
      }
    };
    fetchData();
  }, [username]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  /**
   * @function useEffect - Closes the navigation bar if the screen size is tablet or mobile
   */
  useEffect(() => {
    if (isTabletOrMobile) {
      setNav(false);
    }
  }, [isTabletOrMobile]);

  return (
    <>
      {/* Navbar Open Component*/}
      {isOpen ? (
        <div className="h-screen w-[250px] fixed bg-background_gray">
          <div className="flex items-center justify-between h-8 px-5 mt-4">
            <img
              src="/Optony_Logo_Name_Black 1.svg"
              alt="Logo"
              className="h-8"
            />
          </div>
          <div className="w-full pl-[53px] pt-1 text-[#8a8d89] text-base font-normal font-['Roboto'] leading-tight">
            Pro Forma
          </div>
          <nav className="p-5 mt-4">
            {showLinks && (
              <ul className="">
                {navItems.map((item) => (
                  <NavItem
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    label={item.label}
                    isOpen={true}
                  />
                ))}
              </ul>
            )}
          </nav>
          {/* Navbar Open Component Image Section*/}
          <div className="w-full flex flex-col justify-center p-4 absolute bottom-0 items-center">
            {parentData && parentData.image_url && screenHeight > 850 && (
              <LearnMorePromoCard
                imageUrl={parentData.image_url}
                contactLink={parentData.contact_link}
                isCollapsed={false}
              />
            )}
            {parentData &&
              parentData.image_url &&
              screenHeight <= 850 &&
              screenHeight >= 500 && (
                <LearnMorePromoCard
                  imageUrl={parentData.image_url}
                  contactLink={parentData.contact_link}
                  isCollapsed={true}
                />
              )}
            {cityAccess && !parentData && screenHeight > 850 && (
              <LearnMorePromoCard
                imageUrl={cityAccess.image_url}
                contactLink={cityAccess.contact_link}
                isCollapsed={false}
              />
            )}
            {cityAccess &&
              !parentData &&
              screenHeight <= 850 &&
              screenHeight >= 500 && (
                <LearnMorePromoCard
                  imageUrl={cityAccess.image_url}
                  contactLink={cityAccess.contact_link}
                  isCollapsed={true}
                />
              )}

            {screenHeight > 700 && <ContactPromoCard isCollapsed={false} />}
            {screenHeight <= 700 && screenHeight >= 500 && (
              <ContactPromoCard isCollapsed={true} />
            )}

            {/* Regular Navbar Closed Component*/}
            {showLinks ? (
              <div className="w-full flex justify-center mt-5">
                <DropdownMenu>
                  <DropdownMenuTrigger>
                    <div className="flex w-[200px] items-center justify-between hover:bg-[#e4e6e4] rounded-lg p-2">
                      <div className="flex gap-2 items-center">
                        <Avatar className="shadow-lg">
                          <AvatarImage
                            src={cityInfo?.city_image}
                            alt="@shadcn"
                          />
                          <AvatarFallback>CN</AvatarFallback>
                        </Avatar>
                        <div className="flex flex-col items-start">
                          <div className="text-[#40423f] text-[15px] font-normal font-['Roboto'] leading-[18px]">
                            {cityInfo?.city_name}
                          </div>
                          <div className="text-[#8a8d89] text-[13px] font-normal font-['Roboto'] leading-none">
                            {username}
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <button>
                          <img src="icons/userAction.svg"></img>
                        </button>
                      </div>
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-[200px]">
                    <DropdownMenuItem onClick={handleLogout}>
                      <ExitIcon className="mr-2 h-4 w-4" />
                      <span>Logout</span>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            ) : (
              // Master Access Navbar Closed Component

              <div className="w-full flex flex-col items-start pl-2 pt-2 justify-center ">
                <NavLink to="/login">
                  <Button className="h-10 px-4 flex gap-2" variant="outline">
                    <div className="text-[#1b1c1b] text-[14.95px] font-normal font-['Roboto'] leading-[17.94px]">
                      Logout
                    </div>
                    <ExitIcon className="" />
                  </Button>
                </NavLink>
              </div>
            )}
          </div>
        </div>
      ) : (
        // Navbar Closed Component
        <div className="h-screen w-[75px] fixed bg-background_gray">
          <div className="flex items-center justify-left h-8 pl-5 mt-4">
            <button onClick={toggleNav}>
              <img src="icons/collapsedLogo.svg"></img>
            </button>
          </div>
          <nav className="p-2 mt-4 mx-auto flex flex-col items-center">
            {showLinks && (
              <ul className="">
                {navItems.map((item) => (
                  <NavItem
                    key={item.to}
                    to={item.to}
                    icon={item.icon}
                    label={item.label}
                    isOpen={false}
                  />
                ))}
              </ul>
            )}
          </nav>
          {showLinks ? (
            // Regular Navbar Closed Component
            <div className="w-full flex flex-col justify-center absolute bottom-0">
              <DropdownMenu>
                <DropdownMenuTrigger>
                  <div className="rounded-full p-2 flex justify-center hover:bg-[#e4e6e4] w-full">
                    <Avatar className="shadow-lg">
                      <AvatarImage src={cityInfo?.city_image} alt="@shadcn" />
                      <AvatarFallback>CN</AvatarFallback>
                    </Avatar>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-[200px]">
                  <DropdownMenuItem onClick={handleLogout}>
                    <ExitIcon className="mr-2 h-4 w-4" />
                    <span>Logout</span>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ) : (
            // Master Access Navbar Closed Component
            <div className="w-full flex flex-col items-start pl-5 justify-center absolute bottom-2">
              <NavLink to="/login">
                <Button variant="outline" className="">
                  <ExitIcon className="" />
                </Button>
              </NavLink>
            </div>
          )}
        </div>
      )}
    </>
  );
};

Navbar.propTypes = {
  showLinks: PropTypes.bool,
};

export default Navbar;
