import { getAuthenticatedClient } from "../supabaseClient";

const updatePhaseField = async (field, value, id) => {
  const supabase = getAuthenticatedClient();

  const updateFields = { [field]: value };

  const { data, error } = await supabase
    .from("phases")
    .update(updateFields)
    .eq("id", id)
    .select();

  if (error) {
    throw new Error(`Failed to update data: ${error.message}`);
  }

  return data;
};

const addPhase = async (body) => {
  const supabase = getAuthenticatedClient();
  const {
    user_id,
    site,
    year,
    loan_amount,
    trenching_costs,
    upgrade_cost_utility,
    upgrade_cost_customer,
    procurement_management_cost,
    estimated_public_works_engineering_costs,
    capital_planning_funding,
    port_less_than_10_kw,
    port_10_20_kw,
    port_25_kw,
    port_180_200_kw,
    incentives,
  } = body;

  // Perform data validation
  if (!user_id) {
    return { error: "Missing required field" };
  }

  const { data, error } = await supabase
    .from("phases")
    .insert({
      user_id,
      site,
      year,
      loan_amount,
      trenching_costs,
      upgrade_cost_utility,
      upgrade_cost_customer,
      procurement_management_cost,
      estimated_public_works_engineering_costs,
      capital_planning_funding,
      incentives,
      port_less_than_10_kw,
      port_10_20_kw,
      port_25_kw,
      port_180_200_kw,
    })
    .select();

  if (error) {
    return { error: error };
  }
  return data[0];
};

const deletePhase = async (id) => {
  const supabase = getAuthenticatedClient();

  const response = await supabase.from("phases").delete().eq("id", id);

  if (response.error) {
    return { error: response.error };
  }

  return response;
};

export { updatePhaseField, addPhase, deletePhase };
